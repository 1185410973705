<script setup lang="ts">
import { Button } from '~/components/ui/button';
import IconLogoInstagram from '~icons/icons/logo-instagram';
import IconLogoTikTok from '~icons/icons/logo-tiktok';
import IconLogoX from '~icons/icons/logo-x';

const { $Amplify } = useNuxtApp();
const {
  public: { progressPartitionValue },
} = useRuntimeConfig();
const minionCount = ref<number>(0);

const downloadPdf = async () => {
  const pdf = await $fetch('/Minion_Chososaku_NazoBook_v1.pdf');
  if (!(pdf instanceof Blob)) return;

  const url = window.URL.createObjectURL(new Blob([pdf]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `Minion_Chososaku_NazoBook_v1.pdf`);

  link.click();
};

onMounted(async () => {
  const result = await $Amplify.GraphQL.client.models.MissionProgress.get({
    id: progressPartitionValue,
  });
  minionCount.value = result.data?.minionCount ?? 0;
});
</script>

<template>
  <div class="overflow-hidden">
    <div class="top-fv mx-auto overflow-hidden">
      <div class="top-fv__container container">
        <div class="top-fv__logo">
          <img src="/images/movie-logo.webp" alt="怪盗グルーのミニオン超変身" />
        </div>
        <p class="top-fv__catch block">
          <img src="/images/top/fv-catch.webp" alt="" />
        </p>
      </div>
    </div>

    <section class="first-point relative z-10 text-white">
      <div class="first-point__container container">
        <div class="first-point__minion">
          <img src="/images/top/fv-minion.webp" alt="ミニオンのイラスト" />
        </div>
        <h2 class="first-point__headline text-center">
          <img
            src="/public/images/top/headline-point-1.webp"
            alt="1.ミニオンを見つけてプレゼントに応募"
          />
        </h2>
        <p class="first-point__catch mx-auto block">
          <img
            src="/public/images/top/first-point-catch.webp"
            alt="街や自宅でミニオンを見つけたら、写真を撮ってSNSに投稿しよう！"
          />
        </p>
        <ul class="first-point__sns-list grid grid-cols-3">
          <li class="fill-white">
            <IconLogoX class="w-full" />
          </li>
          <li class="fill-white">
            <IconLogoInstagram class="w-full" />
          </li>
          <li class="fill-white">
            <IconLogoTikTok class="w-full" />
          </li>
        </ul>
        <p class="first-point__text text-center">
          超豪華なプレゼントがあたる<br />
          プレゼントキャンペーンに応募できるぞ！
        </p>
        <img
          class="first-point__present"
          src="/public/images/top/present.webp"
          alt="プレゼントのイメージ写真"
        />
        <NuxtLink class="mx-auto block w-fit" to="/apply?type=x">
          <Button class="first-point__button mx-auto">
            <img
              class="first-point__button-image"
              src="/public/images/top/text-detail-here.webp"
              alt="詳細はこちら"
            />
          </Button>
        </NuxtLink>
      </div>
    </section>

    <div class="gradation-bg-wrap relative z-20 text-white">
      <section class="second-point text-center">
        <div class="second-point__container container">
          <h2 class="second-point__headline text-center">
            <img
              src="/public/images/top/headline-point-2.webp"
              alt="2.ミニオンを見つけてプレゼントを超進化!"
            />
          </h2>

          <p class="second-point__text">
            参加者みんなが<br />
            たくさんのミニオンを見つけるほど<br />
            SNS投稿キャンペーンに<br />
            超豪華プレゼントがどんどん追加されるぞ！
          </p>
          <p class="second-point__text">
            レベルの高い謎を解くと、<br />
            より多くのミニオンが見つかるらしい。<br />
            謎解きに自信のある人は、<br />
            完全クリアにチャレンジしてみよう！
          </p>
        </div>
      </section>

      <section class="challenge">
        <div class="container">
          <h2 class="challenge__headline mx-auto">
            <div class="challenge__headline-minion-left">
              <img
                src="/images/top/found-minion-1.webp"
                alt="ミニオンのイラスト"
              />
            </div>
            <img
              src="/images/top/text-black-found-minion.webp"
              alt="現在発見済のミニオン"
            />
            <div class="challenge__headline-minion-right">
              <img
                src="/images/top/found-minion-2.webp"
                alt="ミニオンのイラスト"
              />
            </div>
          </h2>
          <div class="challenge__number text-center">
            <NumberFormatter v-if="minionCount" :num="minionCount" />
          </div>
          <NuxtLink class="mx-auto block w-fit" to="/missions">
            <Button colors="white" class="challenge__button mx-auto">
              <img
                class="challenge__button-image"
                src="/images/top/text-asap-challenge.webp"
                alt="今すぐチャレンジ!!"
              />
            </Button>
          </NuxtLink>
        </div>
      </section>

      <section class="present">
        <div class="present__container">
          <ol class="present__list mx-auto grid w-fit grid-cols-1">
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade0.webp"
                alt="プレゼント1"
              />
            </li>
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade1.webp"
                alt="プレゼント1"
              />
            </li>
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade2.webp"
                alt="プレゼント2"
              />
            </li>
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade3.webp"
                alt="プレゼント3"
              />
            </li>
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade4.webp"
                alt="プレゼント3"
              />
            </li>
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade5.webp"
                alt="プレゼント4"
              />
            </li>
            <li class="present__item w-full">
              <img
                class="w-full"
                src="/images/top/grade6.webp"
                alt="プレゼント5"
              />
            </li>
          </ol>
        </div>
      </section>

      <section class="illusion">
        <div class="container relative">
          <p class="illusion__content mx-auto">
            <img
              src="/images/top/top-illusion-mystery.webp"
              alt="幻の謎 特別景品"
            />
          </p>
        </div>
      </section>
    </div>

    <section class="found-minion bg-black">
      <div class="container">
        <h2 class="found-minion__headline mx-auto">
          <img
            src="/images/top/text-minion-found.webp"
            alt="現在発見済のミニオン"
          />
        </h2>
        <div class="found-minion__number mx-auto text-center">
          <NumberFormatter v-if="minionCount" :num="minionCount" />
        </div>
        <NuxtLink class="mx-auto block w-fit" to="/missions">
          <Button class="found-minion__button mx-auto">
            <img
              class="found-minion__button-image"
              src="/images/top/text-lets-find-minion.webp"
              alt="謎を解いてミニオンを見つけよう！"
            />
          </Button>
        </NuxtLink>
      </div>
    </section>

    <section
      class="booklet bg-gradient-to-b from-black to-[#0A1A3A] text-white"
    >
      <div class="booklet__container container">
        <h2 class="booklet__headline">
          <img
            src="/images/top/text-booklet-catch.webp"
            alt="東京・名古屋で謎解き冊子を配布中"
          />
        </h2>
        <p class="booklet__caution">
          ※謎解き冊子は数に限りがあります。<br />
          無くなり次第、配布終了とさせていただきます。
        </p>
        <div class="booklet__text-area-wrap">
          <p class="booklet__text-tokyo">
            <img
              src="/images/top/text-booklet-tokyo.webp"
              alt="東京 渋谷モディ・Shibuya Sakura Stage・お台場冒険王"
            />
          </p>
          <p class="booklet__text-nagoya">
            <img
              src="/images/top/text-booklet-nagoya.webp"
              alt="名古屋 JRゲートタワー"
            />
          </p>
        </div>
        <div class="booklet__button-wrap">
          <div class="booklet__button-white-line">
            <img src="/images/top/booklet-white-line.webp" alt="白い点線" />
          </div>
          <p class="booklet__button-catch mx-auto">
            <img
              src="/images/top/text-booklet-button-catch.webp"
              alt="会場限定の謎を解いてもっとミニオンを見つけよう!"
            />
          </p>
          <NuxtLink class="mx-auto block w-fit" to="/missions/05-onsite">
            <Button class="booklet__link-button mx-auto">
              <img
                class="booklet__link-button-image"
                src="/images/top/text-booklet-link.webp"
                alt="回答はこちら"
              />
            </Button>
          </NuxtLink>
          <div class="booklet__button-white-line">
            <img src="/images/top/booklet-white-line.webp" alt="白い点線" />
          </div>
        </div>
        <p class="booklet__dl-text relative z-10">
          <img
            src="/images/top/booklet-fukidashi.webp"
            alt="会場に来られない方は、以下よりダウンロードください。"
          />
        </p>

        <Button
          class="booklet__dl-button mx-auto"
          variant="sharp"
          colors="orange"
          frame-colors="none"
          @click="downloadPdf"
        >
          <img
            class="booklet__dl-button-image"
            src="/images/top/text-booklet-download.webp"
            alt="冊子データダウンロード"
          />
        </Button>
      </div>
    </section>
    <section
      class="about-super-transformation bg-gradient-to-b from-black to-[#0A1A3A] text-white"
    >
      <div class="about-super-transformation__container container">
        <h2 class="about-super-transformation__title">
          <img
            src="/images/top/text-about-super-transformation-title.webp"
            alt=""
          />
        </h2>
        <div class="about-super-transformation__poster-image mx-auto">
          <img
            class="w-full"
            src="/images/top/super-transformation-poster.webp"
            alt="怪盗グルーのミニオン超変身 映画のポスター"
          />
        </div>
        <p class="about-super-transformation__catch mx-auto">
          <img
            class="w-full"
            src="/images/top/super-transformation-catch.webp"
            alt="「ミニオンズ」「ザ・スーパーマリオブラザーズ・ムービー」のイルミネーション最新作すべてが超進化!"
          />
        </p>
        <p class="about-super-transformation__description">
          敵はグルーの“過去”からやってきた。新たな一員が加わったグルーファミリーに危機が迫るとき、スーパーパワーを手に入れたメガミニオンが立ち上がる！この夏、制御不能ヒーローが映画館で大暴れ！
        </p>
        <NuxtLink
          class="mx-auto block w-fit"
          to="https://minions.jp/"
          target="_blank"
        >
          <Button
            class="about-super-transformation__button"
            variant="default"
            colors="transparent"
            frame-colors="white"
          >
            <img
              class="about-super-transformation__button-image"
              src="/images/top/text-movie-official-button.webp"
              alt="映画公式サイトはこちら"
            />
          </Button>
        </NuxtLink>
      </div>
    </section>
  </div>
</template>

<style scoped lang="scss">
// FV
.top-fv {
  width: 100vw;
  height: calcMinSp(1290);
  background-image: url('/public/images/top/fv-bg.webp');
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include mq() {
    height: calcMinSp(1300);
    background-position: 40%;
  }
}

.top-fv__container {
  padding-top: calcMaxSp(20);
}

.top-fv__logo {
  width: calcMinSp(160);
  margin-inline: auto;

  img {
    width: 100%;
    height: auto;
  }
}

.top-fv__catch {
  width: calcMinSp(730);
  height: auto;
  object-position: top;
  margin-left: calcMinSp(20);

  img {
    width: 100%;
    height: auto;
  }
}

.first-point__minion {
  width: calcMinSp(360);
  position: absolute;
  top: calcMaxSp(-340);
  right: calcMaxSp(-110);

  @include mq {
    width: calcMinSp(320);
    top: calcMaxSp(-300);
  }

  img {
    width: 100%;
    height: auto;
  }
}

// ポイント1
.first-point {
  margin-top: calcMaxSp(-100);
  background-image: url('/public/images/top/bg-first-point.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: top;
  background-position-x: 30%;

  @include mq() {
    margin-top: -12.0208333333vw;
  }
}

.first-point__container {
  padding-top: calcMinSp(100);
  padding-inline: calcMinSp(60);
  padding-bottom: calcMinSp(150);
}

.first-point__headline {
  width: calcMinSp(670);
  margin-bottom: calcMinSp(55);
  margin-left: calcMaxSp(-10);
}

.first-point__catch {
  width: calcMinSp(500);
  margin-bottom: calcMinSp(35);
}

.first-point__sns-list {
  width: calcMinSp(290);
  column-gap: calcMinSp(40);
  margin-inline: auto;
  margin-bottom: calcMinSp(30);
}

.first-point__text {
  font-size: calcMinSp(27);
  line-height: calcMinSp(36);
  margin-bottom: calcMinSp(34);
}

.first-point__present {
  width: calcMinSp(510);
  margin-inline: auto;
  margin-bottom: calcMinSp(40);
}

.first-point__button {
  width: calcMinSp(400);
  height: calcMinSp(113);
  box-shadow: calcMinSp(4) calcMinSp(2) calcMinSp(54) rgb(var(--color-blue-500));
}

.first-point__button-image {
  width: calcMinSp(370);
  margin-left: calcMinSp(20);
}

// グラデーション背景のラップ
.gradation-bg-wrap {
  margin-top: spvw(-80);
  background-image: url('/public/images/top/bg-gradation.webp');
  background-size: cover;
}

// ポイント2
.second-point__container {
  padding-top: calcMinSp(100);
  padding-inline: calcMinSp(60);
  padding-bottom: calcMinSp(120);
}

.second-point__headline {
  width: calcMinSp(700);
  margin-bottom: calcMinSp(30);
}

.second-point__text {
  font-size: calcMinSp(27);

  &:not(:last-of-type) {
    margin-bottom: calcMinSp(35);
  }
}

// 今すぐチャレンジ！
.challenge {
  background-image: url('/public/images/top/bg-yellow-border.webp');
  background-size: cover;
  background-repeat: repeat;
  padding: calcMinSp(40) 0 calcMinSp(60);
}

.challenge__headline {
  width: calcMinSp(340);
  margin-bottom: calcMinSp(30);
  position: relative;
}

.challenge__headline-minion-left {
  width: calcMinSp(150);
  position: absolute;
  top: calcMaxSp(-60);
  left: calcMinSp(-120);

  @include mq('sm') {
    width: calcMinSp(140);
    top: calcMaxSp(-60);
    left: calcMinSp(-140);
  }
}

.challenge__headline-minion-right {
  width: calcMinSp(222);
  position: absolute;
  top: calcMaxSp(-115);
  right: calcMinSp(-130);

  @include mq('sm') {
    width: calcMinSp(200);
    top: calcMaxSp(-115);
    right: calcMinSp(-160);
  }
}

.challenge__number {
  margin-bottom: calcMinSp(30);
}

.challenge__button {
  width: calcMinSp(563);
  height: calcMinSp(113);
  box-shadow: calcMinSp(4) calcMinSp(2) calcMinSp(54) rgb(var(--color-blue-500));
}

.challenge__button-image {
  width: calcMinSp(420);
}

// プレゼント
.present {
  margin-bottom: calcMaxSp(-280);
}

.present__container {
  padding: calcMinSp(50) calcMinSp(20);
}

.present__list {
  width: calcMinSp(740);
}

.present__item {
  margin-bottom: calcMaxSp(-30);
}

// 幻
.illusion {
  height: calcMinSp(1100);
}

.illusion__content {
  width: calcMinSp(910);
  height: auto;
  position: absolute;
  top: calcMinSp(290);
  left: calcMaxSp(-110);
  margin: 0 auto;

  @include mq() {
    width: 940px;
    position: absolute;
    top: 290px;
    // left: calcMin(440);
    left: 340px;
    transform: translateX(-50%);
  }

  img {
    width: 100%;
    height: auto;
  }
}

// 現在発見済みのミニオン
.found-minion {
  background-image: url('/public/images/top/bg-found-minion.webp');
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: calcMinSp(385);
  padding-bottom: calcMinSp(130);
}

.found-minion__headline {
  width: calcMinSp(630);
  margin-bottom: calcMinSp(40);
}

.found-minion__number {
  margin-bottom: calcMinSp(50);
  width: calcMinSp(630);
  filter: drop-shadow(
    calcMinSp(4) calcMinSp(4) calcMinSp(54) rgba(255, 255, 255, 0.68)
  );
}

.found-minion__button {
  width: calcMinSp(530);
  height: calcMinSp(119);
  box-shadow: calcMinSp(4) calcMinSp(4) calcMinSp(54) rgb(var(--color-blue-400));
}

.found-minion__button-image {
  width: calcMinSp(560);
  margin-left: calcMinSp(40);
}

// 冊子ミッション
.booklet {
  background-image: url('/public/images/top/bg-booklet.webp');
  background-position: top;
  background-size: cover;
}

.booklet__container {
  padding-top: calcMinSp(50);
  padding-inline: calcMinSp(60);
  padding-bottom: calcMinSp(160);
}

.booklet__headline {
  width: calcMinSp(720);
  margin-left: calcMaxSp(-30);
  margin-bottom: calcMaxSp(-20);

  > img {
    width: 100%;
    height: auto;
  }
}

.booklet__caution {
  font-size: calcMinSp(22);
  margin-inline: auto;
  text-align: center;
}

.booklet__text-area-wrap {
  margin-bottom: calcMinSp(40);
}

.booklet__text-tokyo {
  width: calcMinSp(510);
  margin-inline: auto;
  margin-bottom: calcMinSp(40);
}

.booklet__text-nagoya {
  width: calcMinSp(380);
  margin-inline: auto;
  margin-top: calcMaxSp(-60);
}

.booklet__description {
  font-size: calcMinSp(31);
  margin-bottom: calcMinSp(60);
  text-shadow: calcMinSp(12) calcMinSp(12) calcMinSp(40) rgb(var(--color-black));
}

.booklet__button-wrap {
  margin-bottom: calcMinSp(60);
}

.booklet__button-catch {
  width: calcMinSp(580);
  margin-top: calcMinSp(20);
}

.booklet__link-button {
  width: calcMinSp(400);
  height: calcMinSp(113);
  margin-top: calcMaxSp(-10);
  margin-bottom: calcMinSp(45);
  box-shadow: calcMinSp(4) calcMinSp(4) calcMinSp(54) rgb(var(--color-black));
}

.booklet__link-button-image {
  width: calcMinSp(380);
  margin-left: calcMinSp(20);
  height: auto;
}

.booklet__dl-text {
  width: calcMinSp(373);
  margin-inline: auto;
  margin-bottom: calcMaxSp(-20);
}

.booklet__dl-button {
  width: calcMinSp(480);
  height: calcMinSp(97);
  box-shadow: calcMinSp(4) calcMinSp(2) calcMinSp(50) rgb(var(--color-black));
}

.booklet__dl-button-image {
  width: calcMinSp(400);
  height: auto;
}

// about-super-transformation
.about-super-transformation__container {
  padding-top: calcMinSp(360);
  padding-inline: calcMinSp(60);
  padding-bottom: calcMinSp(100);
}

.about-super-transformation__title {
  width: calcMinSp(767);
  position: absolute;
  top: calcMaxSp(-135);
  left: 0;
}

.about-super-transformation__poster-image {
  width: calcMinSp(640);
  margin-bottom: calcMinSp(35);
}

.about-super-transformation__catch {
  width: calcMinSp(660);
  margin-bottom: calcMinSp(20);
}

.about-super-transformation__description {
  font-size: calcMinSp(27);
  margin-bottom: calcMinSp(70);
}

.about-super-transformation__button {
  width: calcMinSp(557);
  height: calcMinSp(90);
}

.about-super-transformation__button-image {
  width: calcMinSp(460);
  height: auto;
}
</style>
